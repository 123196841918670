<header>
  <h1>Privacy Policy</h1>
</header>
<main>
  <div class="row">
    <!-- Intro -->
    <div class="col-lg-12">
      <h2>Introduction</h2>
      <p>
        These terms and conditions constitute a binding contract as per the
        provisions and rules under the Information Technology Act of 2000, by
        and between the operator of the website, being <b>nkonlinetest</b>, a
        brand under M G Techno Private Limited a company duly incorporated under
        the provisions of the Companies Act, 1956 (hereinafter referred to as
        ‘Company’) and having its registered office at C-58, N. K Tower,
        Lalkothi Scheme, Jaipur, Rajasthan, 302015, India and You. The Company
        is the authorized licensee and reseller of online courses
        <a href="https://www.nkonlinetest.com/">https://www.nkonlinetest.com/</a>
        (here in after referred to as ‘Website’).
      </p>
    </div>

    <!-- Scope -->
    <div class="col-lg-12">
      <h2>Scope</h2>
      <p>
        This Policy describes certain terms and conditions to access and use the
        Website/s of the Company by a visitor or a registered user and order
        products (hereinafter referred to as the “End User”, “You” or “Your”) We
        provide you access to information through the Website and we may provide
        the ability to use certain functionalities of the Website or provide
        services of various kinds (the “Services”).<br />
      </p>

      <p>
        This privacy policy describes our information collection and use
        practices in connection with Website and Service, as well as
        circumstances under which we disclose information collected from you.
        Our commitment to you is that we will not use or disclose information
        collected from you other than as described in this policy. In order to
        access and use the Website and Services, you must agree to our terms and
        conditions (the “terms and conditions “) posted on the Site.<br />
      </p>

      <p>
        Persons and organizations with permission to use the Service are
        considered “Registered Users.” You may view portions of the Website even
        if you are not an Registered User of the Service. This privacy policy
        applies to Company’s collection, use and disclosure of Personal
        Information (defined below) from Registered Users of the Site, as well
        as from persons who merely visit the Site.<br />
      </p>

      <p>
        We have adopted and implemented this privacy policy as part of our
        commitment to protecting your personal information from misuse. By
        submitting Personal Information through our Website or Services, you
        agree to the terms of this Privacy Policy and you expressly consent to
        the collection, use and disclosure of your Personal Information in
        accordance with this Privacy Policy. Your Personal Information may be
        processed in the country in which it was collected and in other
        countries, including the United States, where laws regarding processing
        of Personal Information may be less stringent than the laws in your
        country.<br />
      </p>
    </div>

    <!-- Collection info -->
    <div class="col-lg-12">
      <h2>Collection of Information</h2>
      <p>
        What Information Do We Collect? “Personal Information” means data that
        allows someone to identify or contact you, including, for example, your
        name, address, telephone number, e-mail address, as well as any other
        non-public information about you that is associated with or linked to
        any of the foregoing data. “Non Personal Information” means data that is
        not associated with or linked to your Personal Information; Non-Personal
        Information does not, by itself, permit the identification of individual
        persons.<br />
      </p>

      <p>
        We collect Personal Information and Non-Personal Information, as
        described below.<br />
      </p>
    </div>

    <!-- Personal info -->
    <div class="col-lg-12">
      <h2>Personal Information</h2>
      <p>
        Company collects Personal Information as required to offer Services, as
        listed in the terms and conditions, as voluntarily provided by you in
        the course of correspondence or registration, and as otherwise
        identified below. In addition, we collect certain information which
        cannot be used to personally identify any user, such as anonymous,
        aggregate or statistical data. In order to use some Services, you may be
        required to complete the applicable registration form accessible on the
        Site. All persons who register are referred to as “Registered Users.”
        Some Services may not require registration.<br />
      </p>

      <p>
        We collect and maintain a record of all data provided through such
        registration forms, e.g. your name, e-mail, mailing addresses, and
        password and information about your child, e.g., your child’s name,
        birthday, interests, and gender. For example, individual users who
        desire to order a Product (as defined in the terms and conditions) must
        provide certain contact information, shipping information, billing
        information, and f inancial information (e.g., a credit card number and
        expiration date) (which will be collected by us or our third-party
        payment processing service providers). For some Services, each
        Registered User may be provided a unique username and password that
        enable access to the Service. We may track the use of those usernames
        and passwords in order to collect and maintain records of individual
        transactions conducted through the Services by Registered Users, and
        those records may be associated with each Registered User’s individual
        account profile within the Company system.<br />
      </p>

      <p>
        We also collect other types of Personal Information that you provide to
        us voluntarily, such as your operating system and version, product
        registration number, and other requested information if you contact us
        via e-mail regarding support. We also collect and maintain records of
        information that you voluntarily provide to us. For example, we may ask
        you to participate in a survey, and may ask you to provide Personal
        Information, such as age, gender or other demographic data. If you enter
        into a contest or promotion on our Site, we will collect the information
        you provide to participate in the contest or promotion.<br />
      </p>

      <p>
        If you provide us feedback or contact us via e-mail, we will collect
        your name and e mail address, as well as any other content included in
        the e-mail, in order to send you a reply. When you post messages on the
        message boards of our Site, the information contained in your posting
        will be stored on our servers and other users will be able to see it.
        However, unless such information is necessary for the Service or
        required by the terms and conditions, we do not require you to provide
        such information to us. We collect additional information through the
        Website and the Service that is not associated with any online account
        or registration information, and that cannot be used to personally
        identify you.<br />
      </p>
    </div>

    <!-- Non personal info  -->
    <div class="col-lg-12">
      <h2>Non-Personal Information</h2>
      <p>
        Non-Personal Information ordinarily includes aggregate, summary, or
        other anonymous data, and may include, by way of example, statistics
        regarding popularity of particular pages within the Site, or information
        regarding types of Internet browsers used by Registered Users. “Cookies”
        are small files placed on a user’s computer by a Web site in order to
        facilitate use of that Web site. For example, a cookie may contain
        information about a user’s preferences, so that the Web site
        automatically complies with those preferences whenever the user visits
        that Web site. When using the Service, we may create “cookies” on your
        computer for purposes of facilitating and maintaining your current user
        session, and may collect Personal Information using those cookies in
        order to enable Company to identify you in connection with the Service
        and to track use of the Service for purposes consistent with this
        policy. To make our Website and Services more useful to you, our servers
        (which may be hosted by a third-party service provider) collect
        information from you, including your browser type, operating system,
        Internet Protocol (IP) address (a number that is automatically assigned
        to your computer when you use the Internet, which may vary from session
        to session), domain name, and/or a date/time stamp for your visit.<br />
      </p>

      <p>
        The Website is not directed to children and children are not eligible to
        use our Services. Protecting the privacy of children is very important
        to us. We do not collect or maintain Personal Information from people we
        actually know are under 18, and no part of our website or service is
        designed to attract people under 18. If we later learn that a user is
        under 18, we will take steps to remove that user’s Personal Information
        from our databases and to prevent the user from utilizing the Website
        and the Service..<br />
      </p>
    </div>

    <!-- Use of collected info -->
    <div class="col-lg-12">
      <h2>Use of Collected Information</h2>
      <p>
        Company uses information collected from the Website and Service in
        several ways, as outlined in this policy. For example, we use Personal
        Information as needed to provide Website and Services, to create
        Accounts, and to communicate with Registered Users. We also use both
        Personal Information and Non-Personal Information when evaluating ways
        to improve the Website and the Service. As further described below, in
        limited circumstances we may disclose collected information, including
        Personal Information, to certain third parties in connection with
        providing Services or as required by law. We will not use or disclose
        collected information in ways different from what is described in this
        policy unless otherwise required by law.<br />
      </p>

      <p>
        We also use information collected through the Website and the Service to
        communicate with Registered Users from whom such information was
        collected in order to provide promotional materials about us. However,
        unlike communications which are necessary to provide Services
        (“Necessary Communications”), promotional communications are optional
        (“Optional Communications”), and Company will refrain from sending that
        kind of information to you if you inform us that you prefer not to
        receive them, as described in more detail below. Company uses
        Non-Personal Information in a variety of ways, including, by way of
        example, to evaluate the performance of the Website and Service and the
        general preferences of users, or to generate anonymous statistics for
        our benefit or for our business partners.<br />
      </p>

      <p>
        Because Non-Personal Information is entirely anonymous, and therefore
        can never be used by us to identify you, this policy does not limit our
        ability to use or disclose Non Personal Information. We may create
        Non-Personal Information records from Personal Information by excluding
        information (such as your name) that make the data personally
        identifiable to you. If we collect information through the Website
        and/or the Service that you provide to us voluntarily through online
        forms or surveys, through e-mail communications or by other means, we
        will treat that information as described above, as applied to both
        Personal Information and Non-Personal Information.<br />
      </p>
    </div>

    <!-- Disclosure of information -->
    <div class="col-lg-12">
      <h2>Disclosure of Information</h2>
      <p>
        As a necessary part of providing Services, we may disclose information
        collected from you as described in this policy. For example, we may
        disclose certain Personal Information about you to our third-party
        service providers (e.g., our third-party payment processors), so long as
        they are only using the Personal Information to provide services to us.
        If another company acquires our company, business, or our assets, that
        company will possess the Personal Information collected by it and us and
        will assume the rights and obligations regarding your Personal
        Information as described in this Privacy Policy.<br />
      </p>

      <p>
        Under certain limited circumstances, we may cooperate with legal
        investigations and/or we may be subject to legal requirements to
        disclose information collected through the Website or Services, such as,
        by way of example, to a court or a governmental agency, and our policy
        is to provide such cooperation and comply fully with all such legal
        requirements. We may also disclose Personal Information to protect or
        defend the rights or property of Company or users of the Services and/or
        to investigate any violation or potential violation of the law, this
        Privacy Policy, or terms and conditions. The disclosures contemplated by
        this paragraph (“Necessary Disclosures”) are necessary if you wish to
        use the Service. As mentioned above, Company may use and disclose
        Non-Personal Information without restriction. If you do not want us to
        make such Necessary Disclosures, or to use Non-Personal Information
        without restriction, you must not use the Service.<br />
      </p>

      <p>
        We may use third-party advertising companies to serve ads when you visit
        our Site. These companies may use information (not including your name,
        address, email address, or telephone number) about your visits to this
        and other websites in order to provide advertisements about goods and
        services of interest to you. If you enter and win a contest or other
        promotion, you expressly consent to (a) Company providing your Personal
        Information (e.g., your name and address) to the third party providing
        the prize for the contest or other promotion, solely to provide you such
        prize and (b) Company publicly disclosing your name and the fact that
        you won the contest or promotion.<br />
      </p>

      <p>
        If we will be disclosing additional Personal Information in connection
        with a contest or other promotion, we will disclose that to you in a
        separate notice prior to your entering the contest or other promotion.
        By entering such contest or other promotion, you expressly consent to
        such disclosures Access to Personal Information Collected from You
        Registered Users may access their Registration Profiles at any time to
        update Personal Information contained within that Registration Profile.
        Subsequent uses or disclosures of information by us will reflect those
        changes, but we have no ability to update any information previously
        disclosed to any third party.<br />
      </p>
    </div>

    <!-- Security measures -->
    <div class="col-lg-12">
      <h2>Security Measures</h2>
      <p>
        You may request deletion of your Personal Information by us, but please
        note that we may be required to keep this information and not delete it
        (or to keep this information for a certain time, in which case we will
        comply with your deletion request only after we have fulfilled such
        requirements). When we delete any information, it will be deleted from
        the active database, but may remain in our archives.<br />
      </p>

      <p>
        The Website has security measures in place to protect against the loss,
        misuse and unauthorized alteration of Personal Information in Company’s
        possession and control. For example, whenever the Service requests that
        Registered Users provide sensitive information, that information is
        encrypted with industry standard encryption techniques. When you are
        viewing a secure page within the Site, such as our checkout form, an
        icon of a padlock will appear as “locked” at the bottom of web browsers
        such as Microsoft Internet Explorer, but the same icon will appear as
        “un-locked” when the user is merely “surfing.” We use PayU to process
        all payment related requests and don’t store any credit card information
        at our end.<a href="http://www.ssl.com/"> http://www.ssl.com/</a> Credit
        card authorization requests are sent using the same encryption
        technology.<br />
      </p>

      <p>
        Furthermore, access to Personal Information is physically restricted
        within our own offices, so that only certain of our employees are
        granted access to information as appropriate to perform specific jobs
        and tasks (e.g., performing customer service). Please be aware that,
        although we endeavor to provide security for information in our
        possession and control, no security system can prevent against all
        potential security breaches, and we bear no liability for uses or
        disclosures of Personal Information or Non-Personal Information arising
        in connection with the theft thereof. Likewise, Registered Users are
        responsible for safeguarding the confidentiality of passwords to any
        Services, and we bear no liability for access to, or use or disclosure
        of, Personal Information, if such access, use or disclosure arises in
        connection with the theft or disclosure (whether intentional or
        negligent) of a password. If you have any questions about our security
        measures, you can click here to contact us
        <a href="https://www.nkonlinetest.com/">https://www.nkonlinetest.com/</a>
        <br />
      </p>
    </div>

    <!-- Media Policy -->
    <div class="col-lg-12">
      <h2>Media Policy</h2>
      <p>
        The media information (photos, comments, videos) that you post on our
        community platforms might be used to promote our products or in
        marketing campaigns. We will not rent or sell your information to third
        parties outside <b>nkonlinetest</b> a brand under the Company M G Techno
        Savvy Private Limited.<br />
      </p>
    </div>

    <!-- Changes to policy -->
    <div class="col-lg-12">
      <h2>Changes to Policy</h2>
      <p>
        If we change our privacy policy, we will post those changes on the
        Website so that users are always aware of what information we collect,
        how we use it, and under what circumstances, if any, we will disclose
        it. Any changes will be effective upon the earlier of thirty (30)
        calendar days following our dispatch of an e-mail notice to you or
        thirty (30) calendar days following our posting of notice of the changes
        on our Site. These changes will be effective immediately for new users
        of our Service. In any event, changes to this Privacy Policy may affect
        our use of Personal Information that you provided us prior to our
        notification to you of the changes. If you do not wish to permit changes
        in our use of your Personal Information, you must notify us prior to the
        effective date of the changes that you wish to deactivate your account
        with us.<br />
      </p>
    </div>
    <!-- Conclusion -->
    <div class="col-lg-12">
      <h2>Conclusion</h2>
      <p>
        Your continued access to and/or use of the Website or Service following
        notice of such changes constitutes your assent to, and acceptance of,
        this policy, including all posted changes for which we have provided
        notice as described herein.”<br />
      </p>
    </div>
  </div>
</main>
